<template>
  <div class="p-5 pt-10 xl:px-0">
    <transition name="fade">
      <FlashMessage :message="message" v-if="message" />
      <FlashMessage :error="error" v-if="error" key="error" />
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="areas.length" class="text-3xl">
        <div v-for="area in areas" :key="area.id" class="flex w-full area items-center mb-8" :class="area.colour">
          <div class="inline-flex mr-10 w-2/12">
            <span class="relative inline-block pr-9">
              <span>{{ area.name }}</span>
              <div class="flex mt-3 justify-between absolute right-0 top-0">
                <modal title="Are you sure?" class="cursor-pointer ml-1 flex text-gray-500" buttonClass="flex items-start cursor-pointer" component="DeleteConfirm" :attributes="{ message: 'Are you sure you want to delete this area? All topics and slides will be removed. This can NOT be undone', id: area.id, resource: 'areas' }" v-permission="['areas.delete']">
                  <font-awesome-icon icon="times" class="text-xs" />
                </modal>
                <modal title="Area" class="ml-2 cursor-pointer flex text-base text-gray-500" buttonClass="flex items-start cursor-pointer" :attributes="{ payload: area }" component="Area" width="lg" v-permission="['areas.edit']">
                  <font-awesome-icon icon="pen" class="text-xs" />
                </modal>
              </div>
            </span>
          </div>

          <div v-for="topic in area.topics" :key="topic.id" class="mr-3">
            <div class="relative">

            
            <a :href="'/topic/' + topic.id" class="relative">
              <span v-html="topic.legend" class="area__topic"></span>
            </a>
            <div class="flex mt-2 justify-between absolute w-full">
              <modal title="Are you sure?" class="cursor-pointer ml-1 flex text-gray-500" buttonClass="flex items-start cursor-pointer" component="DeleteConfirm" :attributes="{ message: 'Are you sure you want to delete this area? All topics and slides will be removed. This can NOT be undone', id: topic.id, resource: 'topics' }" v-permission="['topics.delete']">
                <font-awesome-icon icon="times" class="text-xs" />
              </modal>
              <modal title="Topic" class="ml-2 cursor-pointer flex text-base text-gray-500" buttonClass="flex items-start cursor-pointer" :attributes="{ payload: topic }" component="Topic" width="lg" v-permission="['topics.edit']">
                <font-awesome-icon icon="pen" class="text-xs" />
              </modal>
            </div>
          </div>
          </div>

          <modal title="Topic" class="bg-gray-600 text-white justify-self-end ml-6 h-full" :attributes="{ area_id: area.id }" buttonClass="cursor-pointer p-2 flex items-center h-full" component="Topic" width="lg" v-permission="['topics.edit']">
            <font-awesome-icon icon="plus" class="text-lg" />
          </modal>
        </div>
      </div>
      <div v-else>
        <p>There are no areas setup</p>
      </div>
    </transition>

    <footer class="fixed left-0 bottom-0 w-full bg-gray-900" v-permission="['areas.edit']">
      <div class="flex items-center justify-between flex-wrap bg-teal-500 p-6 container mx-auto">
        <modal title="Area" class="cursor-pointer" component="Area" width="lg">
          <BaseBtn type="span" icon="plus" text="Add area" class="border-white" />
        </modal>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPen, faKey, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/BaseBtn";

library.add(faPen, faKey, faTimes);

export default {
  name: "Curriculum",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn },
  computed: {
    ...mapGetters("area", ["loading", "error", "areas", "meta", "links"]),
  },
  data() {
    return {
      message: "",
    };
  },
  created() {
    this.getAreas();

    console.log("area", this.areas);

    this.$eventBus.$on("areas:updated", (val) => {
      this.getAreas();
      this.message = "Area updated";
    });
    this.$eventBus.$on("areas:added", (val) => {
      this.getAreas();
      this.message = "Area added";
    });
    this.$eventBus.$on("record:deleted", (val) => {
      this.getAreas();
      this.message = "Area deleted";
    });

    this.$eventBus.$on("topics:updated", (val) => {
      this.getAreas();
      this.message = "Topic updated";
    });
    this.$eventBus.$on("topics:added", (val) => {
      this.getAreas();
      this.message = "Topic added";
    });
    this.$eventBus.$on("record:deleted", (val) => {
      this.getAreas();
      this.message = "Topic deleted";
    });
  },
  methods: {
    getAreas() {
      const currentPage = 1;
      this.$store.dispatch("area/getAreas", currentPage);
    },
  },
};
</script>
